
/**
 * @file CONSTANTS.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Schema
 * @description Shared constants.
 */

export default {

  computed: {

    // Component constants
    HTTP_JSON_HEADER: () => {
      return {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    },
    HTTP_JS_HEADER: () => {
      return {
        headers: {
          'Accept': '*/*',
          'Content-Type': '*/*'
        }
      }
    },
    FETCH_ERROR: () =>
      'The server seems to be unreachable. Please check your internet\
          connection or try again later.',
    SUBMIT_ERROR: () =>
      'Something went wrong!\nPlease try again later.',
    OFFLINE_ERROR: () =>
      'This device appears to be offline, please try again when connected.',
    SUBMIT_SUCCESS_MESSAGE: () =>
      'The registration has been successfully submitted.',
    SUBMIT_SUCCESS_BUTTON: () =>
      'Register another'
  }
}