
/**
 * @file Validation.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Schema
 * @description Validator loading and application utilities.
 * TODO: docs
 */

import Vue from 'vue'

export default {

  data: function () {
    return {
      validator: null,
      lastModel: {}
    }
  },

  methods: {

    // TODO: docs
    async fetchValidator() {

      const routeName = this.$route.name
      var validator

      try {

        switch (routeName) {
          case 'Register':

            validator = (
              await Vue.axios.get(
                this.apiURL(
                  'js-validator'
                ),
                this.HTTP_JS_HEADER
              )
            ).data

            // Convert validator javascript string into a function
            this.validator = (data) => new Function(
              `${validator}; return validate`
            )()(data)

            break

          // Use schema from examples
          case 'Example':

            this.validator = () => { }
            break
        }

      } catch (error) {
        console.log(error)
      }
    },

    // TODO: docs
    serverValidationErrors(errors) {

      var composed = {}

      errors.forEach(
        (error) => {
          var
            message = error.message || error.error,
            parts = message.match(/^([^:]+): ?(.+)$/),
            paths = [...parts[1].matchAll(/[a-zA-Z.]{2,}/g)].map(
              (value) => value[0]
            ),
            fields = paths.map(
              (value) => `/${value.replaceAll('.', '/')}`
            )

          paths.forEach(

            (path, index) => {

              composed[fields[index]] = composed[fields[index]] || []
              composed[fields[index]].push(
                {
                  message: parts[2],
                  fields
                }
              )
            }
          )
        }
      )

      return composed
    }
  }
}