
/**
 * @file Localise.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Localise
 * @description Localisation of text.
 * TODO: docs
 */

import translate from "translate"

translate.engine = "google"
translate.key = "INSERT_GOOGLE_API_KEY_HERE";

export default {

  data: function () {
    return {
      locale: false,
      localisedSchema: {},
      sourceText: {},
      translatedText: {}
    }
  },

  methods: {

    // TODO: docs
    extractText(node) {

      if (typeof node == 'string') {

        this.sourceText[node] = node

      } else {
        [
          node.title,
          node.description,
          node.suffix,
          node.subtitle,
          node.submit,
          node['footer-message'],
          ...(node.enum || [])
        ]
          .filter((text) => !!text)
          .forEach(
            (text) => this.sourceText[text] = text
          )
      }
    },

    // TODO: docs
    async translate(){

      if (!this.localisedSchema[this.locale]) {

        //   let schema = this.copy(this.localisedSchema.en)
        this.translatedText[this.locale.code] = await this.fetchTranslation()
        let schema = JSON.stringify(
          this.schema
        )

        for (const [from, to] of Object.entries(this.translatedText[this.locale.code])){
          schema = schema.replaceAll(`"${from}"`, `"${to}"`)
        }

        // this.translatedText[this.locale.code].entries().forEach(
        //   (to, from) => schema = schema.replaceAll(from, to)
        // )

        schema = this.localisedSchema[this.locale.code] = JSON.parse(schema)

      //   console.log(this.translatedText)

      //   this.traverse(
      //     { properties: { schema } },

      //     ({ node }) => this.insertTranslation(node),

      //     {
      //       context: this,
      //       getChildren: (node) =>
      //         node.properties ||
      //         node.allOf ||
      //         node.oneOf ||
      //         []
      //     }
      //   )
      //   this.localisedSchema[this.locale.code] = schema
      }

      this.schema = this.localisedSchema[this.locale.code]
    },

    // TODO: docs
    insertTranslation(node) {

      let translated = this.translatedText[this.locale.code];

      [
        'title',
        'description',
        'suffix',
        'enum',
        'subtitle',
        'submit'
      ]
        .filter(
          (property) => !!node[property])
        .map(

          (property) => {

            if (typeof node[property] == 'string') {
              node[property] = translated[node[property]]
            } else {

              node.tipe = 'object'
              node.oneOf = []

              node[property].forEach(
                (enumValue, index) => node.oneOf[index] = {
                  title: translated[enumValue],
                  value: enumValue,
                  type: 'string'
                }
              )

              delete node.enum
            }
          }
        )
    },

    // TODO: docs
    async fetchTranslation(){

      let
        hashes = Object.keys(this.sourceText),
        text = Object.values(this.sourceText),
        translatedText = await translate(
          text.join('\n'),
          { to: this.locale.code }
        ),
        translatedArray = translatedText.split('\n')

      return hashes.reduce(
        (combined, value, index) => {
          combined[value] = translatedArray[index]
          return combined
        },
        {}
      )
    }
  }
}