
/**
 * @file Offline.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Schema
 * @description POST request queueing.
 * TODO: docs
 */

export default {

  data: function () {
    return {
      queue: null,
      online: navigator.onLine,
      offline: navigator.onLine,
      offlineIndicator: !navigator.onLine
    }
  },

  created: function () {

    console.log('==== ATTACHING ====')

    let thisObject = this

    this.boundWindowEvent = function() {
      console.log('==== UPDATING STATE ====')
      thisObject.online = navigator.onLine
      thisObject.offline = !thisObject.online
      thisObject.offlineIndicator = thisObject.offline
    }
    this.boundWindowEvent()

    window.addEventListener('online', this.boundWindowEvent)
    window.addEventListener('offline', this.boundWindowEvent)
  },

  destroyed: function() {
    console.log('==== DETACHING ====')
    window.removeEventListener('online', this.boundWindowEvent)
    window.removeEventListener('offline', this.boundWindowEvent)
    this.boundWindowEvent = null
  },

  methods: {

    offlineAvailable(){
      return (
        // serviceWorkerSupported
        ('serviceWorker' in navigator) &&
        // cacheSupported
        ('caches' in window) &&
        // bgSyncSupported
        (
          'serviceWorker' in navigator &&
          'SyncManager' in window
        ) &&
        // periodicSyncSupported
        (
          'serviceWorker' in navigator &&
          typeof ServiceWorkerRegistration.prototype.periodicSync
            !== 'undefined'
        ) &&
        // indexedDbSupported
        (
          window.indexedDB ||
          window.mozIndexedDB ||
          window.webkitIndexedDB ||
          window.msIndexedDB
        ) &&
        // storageSupported
        (
          'storage' in navigator &&
          'StorageManager' in window
        ) &&
        // persistentStorageSupported
        (
          navigator.storage &&
          navigator.storage.persist
        )
      )
    }
  }
}