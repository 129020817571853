
<!-- ------------------------- Locale Component -------------------------- -->


<template>

  <section class="he-locale">

    <v-select v-if="locales"

      item-text="language"
      menu-props="right"
      return-object

      :label="label"
      :value="value"
      :items="locales"

      :dense="dense"
      :filled="filled"
      :outlined="outlined"
      :flat="flat"
      :solo="solo"

      @input="onInput"
    >

      <template slot="item" slot-scope="data">
        <span class="flag">
          {{ flag(data.item.country).emoji }} {{ data.item.language }}
        </span>
      </template>

      <template slot="selection" slot-scope="data">
        <span class="flag">{{ flag(data.item.country).emoji }}</span>
      </template>

    </v-select>

  </section>

</template>


<!-- ----------------------------------------------------------------------- -->


<script>

  /**
   * @file Username.vue
   * @author Scheepers de Bruin
   * @module fields/Locale
   *
   * TODO: doc
   */


  const emojiFlags = require('emoji-flags')


  export default {

    inheritAttrs: true,

    /* ---------- Properties ---------- */

    props: [
      'label', 'value', 'locales',
      'dense', 'filled', 'outlined', 'flat', 'solo'
    ],

    /* ---------- Component methods ---------- */

    methods: {

      // TODO: docs
      flag(countryCode){
        return emojiFlags.countryCode(countryCode)
      },

      // TODO: docs
      onInput(value){
        this.$emit('input', value)
      }
    }
  }

</script>