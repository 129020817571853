
/**
 * @file Schema.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Schema
 * @description Schema loading and preparation utilities.
 *
 * @vue-data {object} [schema] Schema to be rendered as a registration form.
 * @vue-data {object} [schemaLookupOptions] Lookup field options retrieved from the
 *                    server.
 * @vue-data {boolean} [schemaLoading] Indicates whether the scema and lookups are
 *                     still schemaLoading.
 *
 * @vue-computed {string} [schemaTitle] Title from schema or 'Loading...' if the
 *                        schema is still being retrieved.
 * @vue-computed {string} [schemaTitle] Title from schema or 'Loading...' if the
 * @vue-computed {string} [schemaSubTitle] Sub-schemaTitle from schema or 'Loading...'
 *                        if the schema is still being retrieved.
 * @vue-computed {string} [schemaLogo] Logo from schema.
 * @vue-computed {string} [schemaFooterMessage] Footer message from the schema.
 * @vue-computed {object} [schemaFooterLinks] Dictionary of links for the footer
 *                        from the schema.
 *
*/

import Vue from 'vue'
import traverse from '@/components/mixins/data/traverse.mixin.js'
import copy from '@/components/mixins/data/copy.mixin.js'

export default {

  mixins: [traverse, copy],

  data: function () {
    return {
      schema: null,
      schemaLookupOptions: {},
      schemaLoading: 0
    }
  },

  computed: {

    schemaTitle: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['title'] || ''
        : 'Loading...'
    },

    schemaSubTitle: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['subtitle'] || ''
        : ''
    },

    schemaLogo: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['logo']
        : ''
    },

    schemaFooterMessage: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['footer-message']
        : ''
    },

    schemaFooterLinks: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['footer-links']
        : ''
    },

    // TODO: doc
    schemaLocales: function () {
      return this.schema && this.schema['he-metadata']
        ? this.schema['he-metadata']['locales']
        : []
    },
  },


  methods: {

    /* ----- Schema fetching and processing ----- */

    /**
     * Fetches the schema from the server.
     */
    async fetchSchema() {

      const routeName = this.$route.name
      var schema

      this.startSchemaRequest()

      try {

        if (routeName == 'Register' || routeName == 'Local') {
          schema = (
            await Vue.axios.get(
              this.apiURL(
                'definition'
              ),
              this.HTTP_JSON_HEADER
            )
          ).data.data
        } else {
          schema = require(
            '../../assets/examples/' +
            `${this.$route.params.schema}` +
            '.schema.json'
          )
        }

        this.prepareSchema(schema)
        this.endSchemaRequest(schema)

      } catch (error) {

        console.log(error)
        this.schema = null

        this.dialog = {
          type: 'error',
          message: this.FETCH_ERROR,
          visible: true
        }
      }
    },

    /**
     * Prepares a schema for rendering.
     * @param {object} schema Schema to be prepared.
     */
    prepareSchema(schema) {

      this.traverse(

        schema,

        ({ key, node, childKeys, children, path }) => {

          this.sortSchemaNodeChildren(childKeys, children)

          if (node.type == "checkbox") node['x-display'] = 'custom-checkbox'

          if (node['he-lookup']) this.processSchemaLookup(
            key,
            path,
            node,
            schema
          )
        },

        {
          context: this,
          getChildren: (node) =>
            node.properties ||
            node.allOf ||
            node.oneOf ||
            node.anyOf ||
            [node.items] ||
            []
        }
      )
    },

    /**
     * Fetches lookup options, prepares field for rendering.
     * @param {string} key Identifying key of the field.
     * @param {array} path Path of the field within the schema.
     * @param {object} node Schema definition of the field.
     * @param {object} schema The entire form schema.
     */
    processSchemaLookup(key, path, node, schema) {
      this.fetchSchemaFieldOptions(key, path, node['he-lookup'], schema)
      // Prevents $ref & properties conflict.
      delete node.$ref
      node['type'] = 'string',
        node['x-display'] = 'custom-tree-select'
    },

    /**
     * Sorts children according to the 'he-weight' properties.
     * @param {array} childKeys identifying keys of the children.
     * @param {object} children dictionary of children objects.
     */
    sortSchemaNodeChildren(childKeys, children) {
      // Sort according to weights
      // TODO: move to server
      if (childKeys.length) {

        childKeys.sort(
          (a, b) => {

            var diff =
              (children[a]["he-weight"] || 0) -
              (children[b]["he-weight"] || 0)

            return diff
          }
        )

        childKeys.forEach(
          (childKey) => {
            let child = children[childKey]
            delete children[childKey]
            children[childKey] = child
          }
        )
      }
    },

    /**
     * Fetches lookup field options.
     * @param {string} key Lookup field key.
     * @param {array} path Lookup field path in the schema.
     * @param {object} lookup Lookup field lookup metadata.
     * @param {object} schema Form schema.
     *
     */
    async fetchSchemaFieldOptions(key, path, lookup, schema) {

      this.startSchemaRequest()

      try {

        var
          tree = (
            await Vue.axios.get(
              this.apiURL(
                'lookup',
                lookup.lookupName,
                lookup.lookupVersion
              ),
              Object.assign(
                { params: { 'includeChildren': true } },
                this.HTTP_JSON_HEADER
              )
            )
          ).data.data.items

        this.prepareSchemaFieldOption(lookup, tree)
        this.endSchemaRequest(schema)

      } catch (error) {

        console.log(error)

        this.dialog = {
          type: 'error',
          message: this.FETCH_ERROR,
          visible: true
        }
      }
    },

    /**
     * Prepares a lookups' option tree for rendering.
     * @param {string} key Lookup field key.
     * @param {object} lookup Lookup field lookup metadata.
     * @param {object} tree Option root tree.
     * @param {array} path Lookup field path in the schema.
     */
    prepareSchemaFieldOption(lookup, tree) {

      // Removes empty child arrays
      this.traverse(

        { children: tree },

        ({ node }) => {
          if (node.children && !node.children.length) {
            delete node.children
          }
        },
        { context: this }
      )

      lookup.tree = tree
    },


    /* ----- Utils ----- */

    /**
     * Increments the request counter to keep track of the number of server
     * requests made.
     */
    startSchemaRequest() {
      this.schemaLoading++
    },

    /**
     * Decrements the request counter when a server request completes.
     * @param {object} schema Form schema.
     * schema['he-metadata']['locales'] = [
     *   { language: 'English', country: 'gb', code: 'en' },
     *   { language: 'Afrikaans', country: 'za', code: 'af' },
     *   { language: 'Portuguese', country: 'pt', code: 'por' },
     *   { language: 'German', country: 'de', code: 'de' },
     * ]
     */
    endSchemaRequest(schema) {

      this.schemaLoading--

      if (this.schemaLoading == 0) {

        // this.extractText(schema['he-metadata'].title)
        // this.extractText(schema['he-metadata'].subtitle)
        // this.extractText(schema['he-metadata'].submit)
        // this.extractText(schema['he-metadata']['footer-message'])

        // this.locale = schema['he-metadata']['locales']
        //   ? schema['he-metadata']['locales'][0]
        //   : false
        // this.localisedSchema['en'] =
        this.schema = schema
      }
    }
  }
}