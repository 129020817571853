
<!-- ---------------------- Dialog Overlay Component ---------------------- -->


<template>

  <!-- Dialog Container -->
  <v-dialog
    content-class="he-dialog"
    v-model="value"
    :persistent='persistant'
    :max-width="maxWidth"
  >
    <v-card class="content">

      <v-row no-gutters>

        <!-- Dialog Icon -->
        <v-col v-if="icon" class="icon" cols="3">
          <v-icon x-large :color="color">{{ icon }}</v-icon>
        </v-col>

        <!-- Dialog Text -->
        <v-col cols="9">
          <v-card-text class="text">
            <slot></slot>
          </v-card-text>
        </v-col>

      </v-row>

      <!-- Dialog Buttons -->
      <v-card-actions v-if="hasOk|| hasOther || hasCancel" class="actions">

        <v-btn v-if="hasOk"

          block small
          elevation="4"
          color="primary"

          :dense="dense"
          :filled="filled"
          :outlined="dense"

          @click="onClick('ok')"

        >{{ ok || 'Ok' }}</v-btn>

        <v-btn v-if="hasOther"

          block small
          elevation="4"
          color="secondary"

          :dense="dense"
          :filled="filled"
          :outlined="dense"

          @click="onClick('other')"

        > {{ other || 'Other' }} </v-btn>

        <v-btn v-if="hasCancel"

          block small
          elevation="4"
          color="secondary"

          :dense="dense"
          :filled="filled"
          :outlined="dense"

          @click="onClick('cancel')"

        > {{ cancel || 'Cancel' }} </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<!-- ----------------------------------------------------------------------- -->


<script>

  /**
   * @file Dialog.vue
   * @author Scheepers de Bruin
   * @module overlay/Dialog
   * @description Represents a modal dialog component.
   *
   * @vue-prop {boolean} [value=false] Whether the dialog is visible.
   * @vue-prop {boolean} [persistent=false] Whether the dialog is persistant.
   * @vue-prop {enum} [type] The type of dialog to display. Allowed values:
   *
   *          [info | success | warning | error | forbidden | locked | unlocked]
   * @vue-prop {boolean} [dense='false'] vuetify dense style property applied to
   *                     buttons.
   * @vue-prop {boolean} [filled='false'] vuetify filled style property applied
   *                     to buttons.
   * @vue-prop {boolean} [outlined='false'] vuetify outlined style property
   *                     applied to buttons.
   *
   * @vue-computed {string} [icon] Identifier of the icon to be used.
   * @vue-computed {string} [color] Color of the icon to be used.
   * @vue-computed {boolean} [hasOk] Whether an Ok button is displayed.
   * @vue-computed {boolean} [hasOther] Whether a custom button is displayed.
   * @vue-computed {boolean} [hasCancel] Whether a Cancel button is displayed.
   *
   * @vue-event {object} [click] Fired when a button is clicked.
   *
  */

  /* Icons */
  import {
    mdiInformationOutline,
    mdiCheckboxMarkedCircleOutline,
    mdiSignCaution,
    mdiAlertOctagon,
    mdiBlockHelper,
    mdiLockOutline,
    mdiLockOpenOutline
  } from '@mdi/js'

  export default {

    /* ----------- State ----------- */

    name: 'He-Dialog',

    /* ----- Properties ----- */

    props: {

      'value': { type: Boolean, required: true },
      'persistant': { type: Boolean, default: true },
      'type': {
        validator: (value) => [
          'info',
          'success',
          'warning',
          'error',
          'forbidden',
          'locked',
          'unlocked'
        ].includes(value) || !value
      },

      'dense': {}, 'filled': {}, 'outlined': {},

      'ok': String,
      'other': String,
      'cancel': String,

      'max-width': { type: Number, default: 320 }
    },

    /* ----- Computed Properties ----- */

    computed: {

      displayIcon: function(){
        return this.icon || this.ICONS[this.type]
      },

      icon: function() {
        return {
          info:     mdiInformationOutline,
          success:     mdiCheckboxMarkedCircleOutline,
          warning:     mdiSignCaution,
          error:     mdiAlertOctagon,
          forbidden:     mdiBlockHelper,
          locked:     mdiLockOutline,
          unlocked:     mdiLockOpenOutline
        }[this.type]
      },

      color: function() {
        return {
          info: 'info',
          success: 'success',
          warning: 'warning',
          error: 'error',
          forbidden: 'error',
          locked: 'error',
          unlocked: 'success'
        }[this.type]
      },

      hasOk: function(){ return this.ok !== undefined },
      hasOther: function(){ return this.other !== undefined },
      hasCancel: function(){ return this.cancel !== undefined }
    },

    /* ---------- Methods ---------- */

    methods: {
      /**
       * Fires click event when a button is clicked.
       * @param {string} [button] name of the button clicked.
       */
      onClick(button){
        this.$emit('click', button)
      }
    }
  }

</script>


<!-- ----------------------------------------------------------------------- -->


<style lang="scss">

  .he-dialog.v-dialog{

    &>.content{
      align-items: center;

      &>.row>.icon{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &>.text{
        text-align: left;
        padding: 1em 2em 1em 0;
      }

      &>.actions{
        padding: 1em;
      }
    }
  }

</style>