<!-- ----------------------- Register View Component ----------------------- -->

<template>
  <section class="he-register-view">
    <v-snackbar
      v-model="offlineIndicator"
      top
      timeout="-1"
      multi-line
      class="app-disconnected"
    >
      <v-icon large>{{ mdiLanDisconnect }}</v-icon>
      <div>
        No internet connection.<br />
        Registrations will be queued until connection is reestablished.
      </div>
      <template v-slot:action="{ attrs }">
        <v-icon large v-bind="attrs" @click="offlineIndicator = false">{{
          mdiCloseCircle
        }}</v-icon>
      </template>
    </v-snackbar>

    <v-card class="content">
      <v-row no-gutters>
        <v-col class="he-register-content" cols="12">
          <!-- Locale selector -->

          <Locale
            v-if="schema && locale"
            flat
            solo
            v-model="locale"
            :locales="schemaLocales"
            @input="translate"
          />

          <!-- Logo element -->

          <v-img v-if="schema" class="schemaLogo" :src="schemaLogo"></v-img>

          <!-- Page header -->

          <header>
            <h1>{{ schemaTitle }}</h1>
            <h2>{{ schemaSubTitle }}</h2>
          </header>

          <!-- Fake elements shown while schemaLoading -->

          <v-skeleton-loader
            v-if="!schema"
            schemaLoading
            type="table-row, list-item@16, actions"
          ></v-skeleton-loader>

          <!-- Form generated from schema -->

          <SchemaForm
            v-if="schema"
            ref="schemaForm"
            wizard

            :errors="errors"
            :schema="schema"
						:value="model"

            @submit="onSubmit"
            @change="onChange"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- Footer -->

    <v-footer v-if="schemaFooterMessage || schemaFooterLinks" class="footer">
      <section class="message">
        {{ schemaFooterMessage }}
      </section>

      <section class="links">
        <a
          v-for="link in schemaFooterLinks"
          class="link"
          :href="link.link"
          :key="link.link"
        >
          {{ link.value }}
        </a>
      </section>
    </v-footer>

    <!-- Status dialogue -->

    <Dialog
      v-model="dialog.visible"
      :ok="dialog.ok"
      :other="dialog.button"
      :type="dialog.type"
      @click="dialog.visible = false"
    >
      {{ dialog.message }}
    </Dialog>
  </section>
</template>

<!-- ----------------------------------------------------------------------- -->

<script>
  /**
   * @file RegisterView.vue
   * @author Scheepers de Bruin
   * @module views/RegisterViews
   * @description Represents a registration view.
   *
   * @vue-data {object} [errors] Server validation errors to be displayed on
   *                    the form.
   * @vue-data {object} [broken] Error dialog options.
   * @vue-data {object} [dialog] Information dialog options.
   *
   * @vue-computed {string} [HTTP_JSON_HEADER] Header for http requests.
   * @vue-computed {string} [FETCH_ERROR]Error message to display on
   *                        schema fetching error.
   * @vue-computed {string} [SUBMIT_ERROR] Error message to display when
   *                        submission fails.
   * @vue-computed {string} [SUBMIT_SUCCESS_MESSAGE] Default success message to
   * display.
   *
   */

  import pointer from "jsonpointer"
  import sha256 from "crypto-js/sha256"
  import Base64 from "crypto-js/enc-base64"
  import utf8 from "utf8"
  import pako from "pako"
  import axios from "axios"

  import SchemaForm from "@/components/forms/SchemaForm"
  import Dialog from "@/components/overlay/Dialog"
  import Locale from "@/components/fields/Locale"

  import Schema from "./mixins/Schema.mixin.js"
  import Validator from "./mixins/Validation.mixin.js"
  import Localise from "./mixins/Localise.mixin.js"
  import Offline from "./mixins/Offline.mixin.js"

  import CONSTANTS from "./mixins/CONSTANTS.mixin.js"

  import {
    mdiLanDisconnect,
    mdiCloudUploadOutline,
    mdiCloseCircle,
  } from "@mdi/js"

  export default {
    components: {
      SchemaForm,
      Dialog,
      Locale,
    },

    mixins: [Schema, Validator, Localise, Offline, CONSTANTS],

    /* ----------- State ----------- */

    data: function () {
      return {

        model: {},
        errors: {},
        validator: false,

        dialog: {
          type: "info",
          ok: null,
          message: "",
          visible: false,
        },
      }
    },

    /* ----------- Computed ----------- */

    computed: {
      mdiLanDisconnect: () => mdiLanDisconnect,
      mdiCloudUploadOutline: () => mdiCloudUploadOutline,
      mdiCloseCircle: () => mdiCloseCircle,
    },

    /* ---------- Lifecycle hooks ---------- */

    /**
     * Hooked to initiate schema fetching.
     */
    mounted() {
      this.fetchSchema()
      this.fetchValidator()
      this.initAPI()
    },

    /**
     * Hooked to update the document schemaTitle when the schema has been loaded.
     */
    updated() {
      document.title = this.schemaTitle
    },

    /* ---------- Component methods ---------- */

    methods: {

      /* ----- Event handlers ----- */

      /**
       * Responds to form vlues being changed
       * @param {object} updated form values.
       */
      onChange(diff) {
        if (!this.api) return

        let identifiers = this.schema["he-identifiers"] || []

        identifiers.forEach((identifier) => {
          Object.keys(diff).forEach((changed) => {

            const
              searchVal = pointer.get(diff[changed], identifier),
              field = pointer.get(
                this.schema,
                identifier.replaceAll('/', '/properties/')
              )

            if (searchVal) {

              clearTimeout(this.fetchTimeout)

              this.fetchTimeout = setTimeout(async () => {

                this.dialog = {
                  type: 'info',
                  message: `Searching by ${field.title}`,
                  visible: true,
                }

                const response = await axios.get(
                  this.apiURL(
                    'record/by-id',
                    encodeURIComponent(identifier),
                    searchVal
                  ),
                  this.HTTP_API_HEADERS
                )

                this.dialog.visible = false

                const items = pointer.get(response, '/data/data/items')

                if (Array.isArray(items) && items.length) {
                  this.$refs.schemaForm.reset(
                    items.pop()
                  )
                }

              }, 1000)
            }
          })
        })
      },

      /**
       * Handles form submission.
       * @param {object} data form data to submit.
       */
      async onSubmit(data) {
        var cleanData = this.copy(data),
          errors = this.validator ? this.validator(cleanData) : false

        errors instanceof Array
          ? this.submitError(errors)
          : this.submitData(cleanData)
      },

      /* ----- Form submission ----- */

      /**
       * Submits form data to the server api.
       * @param {object}
       */
      async submitData(data) {
        try {
          await axios.post(this.apiURL("record"), data, this.HTTP_API_HEADERS)

          this.dialog = {
            type: "success",
            message: [
              pointer.get(this.schema, "/he-metadata/success/message"),
              pointer.get(this.schema, "/he-metadata/success"), // Deprecated
              this.SUBMIT_SUCCESS_MESSAGE,
            ]
              .filter((message) => !!message)
              .shift(),
            ok: [
              pointer.get(this.schema, "/he-metadata/success/button"),
              this.SUBMIT_SUCCESS_BUTTON,
            ]
              .filter((label) => !!label)
              .shift(),
            visible: true,
          }

          this.errors = {}
          this.$refs.schemaForm.reset()
        } catch (error) {
          console.log(error)

          if (error.response) {
            this.submitError(
              pointer.get(error, "/response/data/error/errors")
            )
          } else this.submitError()
        }
      },

      /**
       * Handles submission errors.
       * @param {object} [response]
       */
      submitError(errors) {
        console.log(errors)

        switch (true) {
          case errors instanceof Array:
            this.errors = this.serverValidationErrors(errors)
            break

          case errors && errors.message == "Network Error":
            // Offline
            this.dialog = {
              type: "error",
              message: this.OFFLINE_ERROR,
              visible: true,
              ok: "Ok",
            }
            break

          default:
            // General server error
            this.dialog = {
              type: "error",
              message: this.SUBMIT_ERROR,
              visible: true,
              ok: "Ok",
            }
            break
        }
      },

      /* ----- API ----- */

      /**
       * Initialises the API by extracting Client ID and secret
       */
      initAPI() {
        let decode = (base64) =>
          String.fromCharCode
            .apply(null, pako.inflate(Buffer.from(base64, "base64")))
            .replace(/\n$/, "")

        try {
          this.api = {
            CLIENT: decode(process.env.VUE_APP_HE_REG_CI),
            SECRET: decode(process.env.VUE_APP_HE_REG_CS),
          }

          this.apiHeaders()
        } catch (error) {
          console.log(error)
          this.api = false
        }
      },

      /**
       * Constructs the API HTTP headers.
       * @returns {object} HTTP headers.
       */
      async apiHeaders() {
        try {
          const response = await axios.get(
              [
                process.env.VUE_APP_HE_REG_URL
                  ? process.env.VUE_APP_HE_REG_URL
                  : "",
                `public/now`,
              ].join("/"),
              { headers: { Accept: "application/json" } }
            ),
            timestamp = pointer.get(response, "/data/data/now"),
            signature = utf8.decode(
              Base64.stringify(
                sha256(
                  utf8.encode(`${this.api.CLIENT}${timestamp}${this.api.SECRET}`)
                )
              )
            )

          this.HTTP_API_HEADERS = {
            headers: {
              Accept: "application/json",
              "X-Helium-Registration-Key": this.api.CLIENT,
              "X-Helium-Registration-Tstamp": timestamp,
              "X-Helium-Registration-Signature": signature,
            },
          }

          setTimeout(() => {
            this.apiHeaders()
          }, 270000)
        } catch (error) {
          console.log(error)
          this.HTTP_API_HEADERS = false
        }
      },

      /**
       * Constructs an API URL.
       */
      apiURL() {
        var url = [
          process.env.VUE_APP_HE_REG_URL,
          this.$route.params.access,
          this.$route.params.environment,
          this.$route.params.dataset,
          this.$route.params.type,
          this.$route.params.version,
          ...arguments,
        ].join("/")

        return url
      },
    },
  }
</script>

<!-- ----------------------------------------------------------------------- -->

<style lang="scss">

	.he-register-view {
		padding: 20px 10px 150px;
		max-width: 100vw;

		.app-disconnected .v-snack__content {
			display: flex;
			align-items: center;

			.v-icon {
				display: block;
				margin: 0 0.5em 0 0;
			}
		}

		.post-queue .v-snack__wrapper {
			min-width: unset !important;
			max-width: 50px;
			min-height: unset;

			.v-snack__content {
				padding: 7.5px 0;

				.v-icon {
					margin: 7.5px 15px;
				}

				.count {
					text-align: center;
				}
			}
		}

		.content {
			flex-grow: 1;
			margin: 0 auto;
			max-width: 750px;

			& > .row > .col {
				padding: 32pt 42pt 48pt;
			}

			.schemaLogo {
				max-width: 50%;
				margin: 40pt auto;
			}

			.he-locale {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 75px;

				.flag {
					font-size: 150%;
					position: relative;
					top: 3px;
				}
			}

			.v-input__append-outer svg {
				fill: var(--v-info);
			}
		}

		.footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			justify-content: center;
			text-align: center;

			.message {
				display: block;
				margin: 6pt 12pt;
			}

			.links {
				display: flex;
				min-width: 100%;
				justify-content: center;

				.link {
					display: block;
					margin: 0 6pt;
				}
			}
		}
	}

</style>
